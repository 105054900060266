import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-team-1.png';
// import Meta from './Meta';
import { Helmet } from 'react-helmet'

const Team = () => {
    
    const url = "https://mygrationhub.com";
    const title = "MyGrationHub | A reliable website for every student, traveler, and immigrant. ";
    const keywords = "Migration hub, migrationhub, MygrationHub, MyGration Hub, My Gration Hub";
    const description = "Our team members at Mygration Hub are more than just immigration professionals - they are your trusted allies in navigating the intricate paths of immigration. With roots in Canada, Bangladesh, Australia, and the US, our team consists of same-country citizens who intimately understand the challenges and opportunities inherent in the immigration process.";
    const image = url + logo;

    return (
        <div>

            {/* <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta> */}
            <Helmet>

                <title>{title}</title>
                <meta name="title" content={title}></meta>
                <meta name="keywords" content={keywords}></meta>
                <meta name="description" content={description}></meta>

                <meta property="og:title" content={title}></meta>
                <meta property="og:description" content={description}></meta>
                <meta property="og:image" content={image}></meta>

                <meta name="twitter:title" content={title}></meta>
                <meta name="twitter:description" content={description}></meta>
                <meta name="twitter:image" content={image}></meta>

            </Helmet>

            <section className="padding-top-adjuster common-segment" id="teams-separate">
            
                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                {/* <h3>Global Expertise, Local Understanding</h3> */}
                                <p>
                                    Our team members at Mygration Hub are more than just immigration professionals - they are your trusted allies in navigating the intricate paths of immigration. With roots in Canada, Bangladesh, Australia, and the US, our team consists of same-country citizens who intimately understand the challenges and opportunities inherent in the immigration process.
                                </p>
                                
                                <p>
                                    What sets us apart is our hands-on personal experience. Each member of our team has walked the same path you&apos;re embarking on now, whether it&apos;s applying for visas, obtaining residency, or navigating the complexities of immigration law. Our partner advisors, hailing from diverse provinces across Canada and regions in Australia, further enhance our collective expertise.
                                </p>
                                <p>
                                    We take great pride in our diverse backgrounds and shared dedication to your success. We recognize that every immigration journey is unique, and we are committed to providing personalized support tailored to your specific needs and circumstances.
                                </p>
                                
                                <h3>Join Us on Your Mygration Journey</h3>
                                <p>
                                    Embark on a journey of possibilities with Mygration Hub. Whether you&apos;re seeking opportunities for study, work, or a fresh start in a new country, we are here to guide you every step of the way. Your dreams are the compass, and we are the navigators, charting a course for success together.
                                </p>

                                
                                
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>

    )
}

export default Team