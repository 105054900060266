import React from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import './../assets/css/custom.css';
import aboutUs1 from './../assets/images/banner-about-us-1.png';
import aboutUs2 from './../assets/images/about-us-2.png';
// import Meta from './Meta'

const AboutUs = () => {
    const url = "https://mygrationhub.com";
    const title = "MyGrationHub | A reliable website for every student, traveler, and immigrant. ";
    const keywords = "Migration hub, migrationhub, MygrationHub, MyGration Hub, My Gration Hub";
    const description = "We aspire to become the reliable companion for every student, traveler, and immigrant as they embark on journeys of exploration, learning, and building a brighter future. Through innovative strategies, unwavering honesty, and personalized assistance, our goal is to reshape the realms of education, travel, and immigration, making them inclusive, enriching, and brimming with opportunities. We are proud to boast affiliations with numerous prestigious universities spanning the globe. These partnerships enrich our offerings and expand the horizons of educational opportunities for our clients. With access to a diverse network of academic institutions, we are uniquely positioned to provide comprehensive support and guidance to students pursuing their educational goals internationally. Our dedication lies in fostering connections, breaking barriers, and fostering a world where boundaries serve as gateways to fresh perspective Established at the crossroads of two vibrant nations – Bangladesh and Canada – Mygration Hub is more than just an immigration consultancy. We serve as a bridge linking aspirations to accomplishments, dreams to destinations, and potential to realities.";
    const image = url + aboutUs2;
    return (
        <div>
            {/* <Meta title="We aspire to become the reliable companion for every student, traveler, and immigrant as they embark on journeys of exploration, learning, and building a brighter future. Through innovative strategies, unwavering honesty, and personalized assistance, our goal is to reshape the realms of education, travel, and immigration, making them inclusive, enriching, and brimming with opportunities. We are proud to boast affiliations with numerous prestigious universities spanning the globe. These partnerships enrich our offerings and expand the horizons of educational opportunities for our clients. With access to a diverse network of academic institutions, we are uniquely positioned to provide comprehensive support and guidance to students pursuing their educational goals internationally." keywords="" description="We aspire to become the reliable companion for every student, traveler, and immigrant as they embark on journeys of exploration, learning, and building a brighter future. Through innovative strategies, unwavering honesty, and personalized assistance, our goal is to reshape the realms of education, travel, and immigration, making them inclusive, enriching, and brimming with opportunities. We are proud to boast affiliations with numerous prestigious universities spanning the globe. These partnerships enrich our offerings and expand the horizons of educational opportunities for our clients. With access to a diverse network of academic institutions, we are uniquely positioned to provide comprehensive support and guidance to students pursuing their educational goals internationally." type="" image={aboutUs1} iwidth="" iheight=""></Meta> */}
            <Helmet>
            
            <title>{title}</title>
            <meta name="title" content={title}></meta>
            <meta name="keywords" content={keywords}></meta>
            <meta name="description" content={description}></meta>
            
            <meta property="og:title" content={title}></meta>
            <meta property="og:description" content={description}></meta>
            <meta property="og:image" content={image}></meta>
            
            <meta name="twitter:title" content={title}></meta>
            <meta name="twitter:description" content={description}></meta>
            <meta name="twitter:image" content={image}></meta>

      </Helmet>
            <section className="about-us padding-top-90px" id="about-us">
                <div className="container">

                    <div className='row'>
                        <div className='col-lg-12'>
                            <img className='' alt='' src={aboutUs1}></img>
                        </div>
                    </div>
                    

                    <div className="row">

                        <div className="col-lg-6 margin-top-50p">
                            <div className=" is-first-expanded">
                                <img className='' alt='' src={aboutUs2}></img>
                            </div>
                        </div>

                        <div className="col-lg-6 margin-top-50p">
                            <div className="row" >
                                <div className="col-lg-12">
                                    <div className="items">
                                        <div className='text-align-justify'>
                                            <p>
                                                We aspire to become the reliable companion for every student, traveler, and immigrant as they embark on journeys of exploration, learning, and building a brighter future. Through innovative strategies, unwavering honesty, and personalized assistance, our goal is to reshape the realms of education, travel, and immigration, making them inclusive, enriching, and brimming with opportunities. We are proud to boast affiliations with numerous prestigious universities spanning the globe. These partnerships enrich our offerings and expand the horizons of educational opportunities for our clients. With access to a diverse network of academic institutions, we are uniquely positioned to provide comprehensive support and guidance to students pursuing their educational goals internationally. 
                                            </p>
                                            <p>
                                                Our dedication lies in fostering connections, breaking barriers, and fostering a world where boundaries serve as gateways to fresh perspectives
                                            </p>
                                            <p>
                                                Established at the crossroads of two vibrant nations – Bangladesh and Canada – Mygration Hub is more than just an immigration consultancy. We serve as a bridge linking aspirations to accomplishments, dreams to destinations, and potential to realities. 
                                            </p>
                                        </div>
                                        <div className="main-button-red">
                                            <div className="scroll-to-section"><Link to="/register">CONNECT WITH US</Link></div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className='col-lg-12 items'>
                            {/* <h3>Who We Are</h3>
                            <p>
                                Established at the crossroads of two vibrant nations – Bangladesh and Canada – Mygration Hub is more than just an immigration consultancy. We are a bridge connecting dreams to destinations, aspirations to achievements, and possibilities to realities. Our team of seasoned professionals brings together a wealth of experience in immigration processes, legal expertise, and cultural insights, ensuring a comprehensive and personalized approach for each client.
                            </p> */}

                            <h3>Our Vision</h3>
                            <p>
                                At the core of Mygration Hub is a vision to redefine the immigration consultancy experience. We envision a world where borders are not barriers but gateways to new opportunities. Our aim is to simplify the complexities of immigration processes, making them accessible, transparent, and tailored to the unique needs of each client. Through innovation and dedication, we strive to set new standards in the industry and inspire confidence in every step of the immigration journey.
                            </p>

                            
                            {/* <div className="section-heading text-align-center">
                                <h2>Why Choose Mygration Hub?</h2>
                            </div>
                            <h3>Global Expertise, Local Understanding</h3>

                            <p>
                                With offices in Bangladesh and Canada, we possess a deep understanding of both the challenges and opportunities that come with immigration. Our global perspective allows us to navigate the intricacies of diverse immigration systems, while our local roots enable us to provide personalized guidance that resonates with your unique circumstances.
                            </p>

                            <h3>Client-Centric Approach</h3>
                            <p>
                                Your dreams are our priority. At Mygration Hub, we believe in a client-centric approach that puts you at the center of every decision. Our dedicated team of consultants is committed to understanding your goals, addressing your concerns, and guiding you through a seamless immigration process.
                            </p>

                            <h3>Ethical Practices </h3>
                            <p>
                                Integrity is the cornerstone of our operations. We uphold the highest ethical standards, ensuring transparency, honesty, and fairness in all our interactions. Your trust is our most valuable asset, and we are committed to preserving it throughout your entire immigration process.
                            </p>

                            <h3>Accuracy in Documentation</h3>
                            <p>
                                We understand that accuracy is paramount in the immigration process. Our meticulous attention to detail and commitment to precision ensure that your documentation is error-free, minimizing delays and increasing the likelihood of successful outcomes.
                            </p>


                            <h3>Innovation in Immigration Consultancy </h3>
                            <p>
                                We embrace technology to enhance the immigration experience. Mygration Hub leverages cutting-edge tools and platforms to streamline communication, provide real-time updates, and ensure efficiency in the application process. Our commitment to innovation sets us apart, making your journey with us not only successful but also technologically advanced.
                            </p>

                            <p>
                                Once you Signup in our portal it will take you to a world of opportunities where you’ll be able to navigate every step of your immigration journey. Following are a glimpse of capabilities once you’re inside the client portal of our website:
                            </p>

                            <div className="section-heading text-align-center">
                                <h2>Join Us on Your Mygration Journey</h2>
                            </div>
                            <p>
                                Embark on a journey of possibilities with Mygration Hub. Whether you&lsquo;re seeking opportunities for study, work, or a fresh start in a new country, we are here to guide you every step of the way. Your dreams are the compass, and we are the navigators, charting a course for success together.
                            </p> */}

                        </div>

                    </div>
                </div>
            </section>

            
        </div>

    )
}

export default AboutUs