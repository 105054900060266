import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { CForm, CCol, CFormLabel, CInputGroup, CInputGroupText, CFormSelect, CAvatar, CButton, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CModalFooter, CModal, CModalBody, CModalTitle, CModalHeader, CFormInput, CRow, CLink } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilPeople } from '@coreui/icons'
import moment from 'moment';
import { Vortex } from 'react-loader-spinner'
import { confirmAlert } from 'react-confirm-alert';
import DatePicker from "react-datepicker";

const ApprovedUsers = () => {
    const [users, setUsers] = useState();
    const [advisors, setAdvisors] = useState([]);

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [visible, setVisible] = useState(false)
    const [visibleA, setVisibleA] = useState(false)
    const [clientName, setClientName] = useState("");
    const [clientId, setClientId] = useState("");
    const [agentId, setAgentId] = useState("");
    // const [selectedAgent, setSelectedAgent] = useState("");
    const [isSpenierOpen, setIsSpenierOpen] = useState(false);
    const [messageColor, setMessageColor] = useState("green");

    const [activityId, setActivityId] = useState("");
    const [activity, setActivity] = useState("");
    // const [activityDate, setActivityDate] = useState("");

    const [userActivities, setUserActivities] = useState([]);



    const messageRef = useRef();
    const [message, setMessage] = useState("");

    const [validated, setValidated] = useState(false);
    const [validatedA, setValidatedA] = useState(false);
    const [isActivityEdited, setIsActivityEdited] = useState(false);
    const [activityActionText, setActivityActionText] = useState("Save");


    function openModal(name, id, selectedId) {
        setClientName(name);
        setClientId(id);
        //setSelectedAgent(selectedId);
        setAgentId(selectedId);

        setMessage('');
        setValidated(false);
        setVisible(true);

    }

    function closeModal() {
        setClientName("");
        setClientId("");
        //setSelectedAgent("");
        setAgentId("");
        setVisible(false);
    }

    function openModalA(client) {
        console.log(client);
        setUserActivities();
        setClientId(client?.id);
        setClientName(client?.fullName);
        setVisibleA(true);
        setValidatedA(false);
        loadActivities(client?.id);
    }

    function viewHandleEditActivities(id,note,date) {
        setActivityId(id);
        setActivity(note);
        // setActivityDate(date);
        setIsActivityEdited(true);
        setActivityActionText("Update");
    }

    function cleanActivity() {
        setActivityId("");
        setActivity("");
        // setActivityDate("");
        setIsActivityEdited(false);
        setActivityActionText("Save");
    }




    const loadActivities = async (id) => {
        const controller = new AbortController();
        try {
            const path = `/getClientActivities/${id}`;
            const response = await axiosPrivate.get(path, {
                signal: controller.signal
            });
            setUserActivities(response.data);
        } catch (err) {
            confirmAlert({ title: 'MyGration Hub', message: "Relogin and try again", buttons: [{ label: 'OK', }] });
        }
    }


    const handleFormSubmitA = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            //console.log("no");
            event.preventDefault()
            event.stopPropagation()
        } else {
            //console.log("yes");
            saveActivities(true);
        }

        setValidatedA(false);
        event.preventDefault();


    }

    async function saveActivities() {
        try {
            const formData = {
                id: activityId,
                userInfoId: 0,
                clientInfoId: clientId,
                note: activity
                //,date: activityDate
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            setIsSpenierOpen(true);

            console.log(JSON.stringify(formData));

            let message = isActivityEdited?"Note updated successfully":"Note saved successfully";

            await axiosPrivate.post("/saveClientActivities", JSON.stringify(formData), { headers: headers }).then((res) => {
                if (res.status === 200) {
                    setIsSpenierOpen(false);
                    confirmAlert({ title: 'MyGration Hub', message: message, buttons: [{ label: 'OK' }] });
                    cleanActivity();
                    loadActivities(clientId);
                }
                else {
                    setIsSpenierOpen(false);
                    confirmAlert({ title: 'MyGration Hub', message: "Failed, Try again later", buttons: [{ label: 'OK' }] });
                }

            }, fail => {
                console.error(fail);
                setIsSpenierOpen(false);
                confirmAlert({ title: 'MyGration Hub', message: "Failed, Try again later", buttons: [{ label: 'OK' }] });
            });
        }

        catch (err) {
            console.log(err);
            setIsSpenierOpen(false);
            confirmAlert({ title: 'MyGration Hub', message: "Failed, Try again later", buttons: [{ label: 'OK' }] });
        }

    }


    function closeModalA() {
        setClientId("");
        setClientName("");
        setVisibleA(false);
        setUserActivities();
        cleanActivity();
    }


    async function saveClientAgentMap() {
        try {
            const formData = {
                agentId: agentId !== "" ? parseInt(agentId) : 0,
                clientId: clientId
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            setIsSpenierOpen(true);

            console.log(JSON.stringify(formData));

            await axiosPrivate.post("/agentClientMap", JSON.stringify(formData), { headers: headers }).then((res) => {
                if (res.status === 200) {
                    setIsSpenierOpen(false);
                    setMessageColor("green");
                    setMessage('Partner assigned successfully');
                    messageRef.current.focus();
                }
                else {
                    setIsSpenierOpen(false);
                    setMessageColor("red");
                    setMessage('Partner assigned failed, Try again later');
                    messageRef.current.focus();
                }

            }, fail => {
                console.error(fail);
                setIsSpenierOpen(false);
                setMessageColor("red");
                setMessage('Try Again Later with Re-Login');
                messageRef.current.focus();
            });
        }

        catch (err) {
            console.log(err);
            setIsSpenierOpen(false);
        }

    }


    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            //console.log("no");
            event.preventDefault()
            event.stopPropagation()
        } else {
            //console.log("yes");
            saveClientAgentMap(true);
        }

        setValidated(true);
        event.preventDefault();


    }


    function deleteUserInfo(id, userName, fullName, index) {
        console.log(index);
        confirmAlert({
            title: 'MyGration Hub', message: "Are you sure? You want to delete user - " + fullName,
            buttons: [{
                label: 'Yes',
                onClick: () => deleteDocument(id, userName, index)
            },
            {
                label: 'No',
            }]
        });
    }

    async function deleteDocument(id, userName, index) {
        try {
            const formData = {
                id: id,
                userName: userName
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            await axiosPrivate.post("/deleteUserInfo", JSON.stringify(formData), { headers: headers }).then((res) => {
                if (res.status === 200) {
                    console.log(searchedVal.length);
                    confirmAlert({ title: 'MyGration Hub', message: "User deleted successfully", buttons: [{ label: 'OK' }] });
                    if (searchedVal.length > 0) {
                        setUsers(users.filter((v, i) => v.id !== id));
                    } else {
                        setUsers(users.filter((v, i) => i !== index));
                    }
                } else {
                    confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
                }
            }, fail => {
                confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
            });
        }
        catch (err) {
        }

    }


    function deleteActivitiesConfirm(id, note, index) {
        console.log(index);
        confirmAlert({
            title: 'MyGration Hub', message: "Are you sure? You want to delete note - " + note,
            buttons: [{
                label: 'Yes',
                onClick: () => deleteActivities(id, note, index)
            },
            {
                label: 'No',
            }]
        });
    }

    async function deleteActivities(id, note, index) {
        try {
            const formData = {
                id: id,
                userName: note
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            await axiosPrivate.post("/deleteClientActivities", JSON.stringify(formData), { headers: headers }).then((res) => {
                if (res.status === 200) {

                    confirmAlert({ title: 'MyGration Hub', message: "Note deleted successfully", buttons: [{ label: 'OK' }] });
                    if (userActivities?.length > 0) {
                        setUserActivities(userActivities.filter((v, i) => v.id !== id));
                    } else {
                        setUserActivities(userActivities.filter((v, i) => i !== index));
                    }
                } else {
                    confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
                }
            }, fail => {
                confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
            });
        }
        catch (err) {
        }

    }





    useEffect(() => {
        let isMounted = true;
        let isAdvisorMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/getAllApprovedUserList', {
                    signal: controller.signal
                });
                // console.log(response.data);
                isMounted && setUsers(response.data);
            } catch (err) {
                //console.error(err);
                console.log(err);
                //navigate('/login', { state: { from: location }, replace: true });
            }
        }

        const getAdvisors = async () => {
            try {
                const res = await axiosPrivate.get('/getAllAgentInfoList', {
                    signal: controller.signal
                });
                // console.log(res.data);
                isAdvisorMounted && setAdvisors(res?.data);
            } catch (err) {
                //console.error(err);
                //console.log(err);
                navigate('/login', { state: { from: location }, replace: true });
            }
        }

        getUsers();
        getAdvisors();

        return () => {
            isMounted = false;
            isAdvisorMounted = false;
            controller.abort();
        }
    }, []);

    function viewHandleClick(client) {
        openModalA(client);
    }

    function viewHandleEditClick(value) {
        localStorage.setItem('edit-user-id', value);
        localStorage.setItem('back-page', '/home/approved-users');
        navigate('/home/edit-user/' + value, { state: { id: value, backPage: '/home/approved-users' }, replace: true });
    }

    function idToStatus(id) {
        return id === 4 ? "Assessment Ongoing" : id === 5 ? "Info Submitted" : id === 6 ? "Case Profile Reviewed" : id === 7 ? "Discuss Later" : "More"

    }

    function idToAdvisor(id) {
        let name = "";
        if (id) {
            const obj = advisors?.filter((row) => row?.id === id);
            if (obj) {
                name = obj[0]?.fullName
            }
        }
        if (name === "" || name === "undefined") {
            name = "Select"
        }
        return name;
    }




    const [searchedVal, setSearchedVal] = useState("");

    return (
        <article>



            {/* <CButton onClick={() => setVisible(!visible)}>Launch demo modal</CButton> */}
            <CModal
                backdrop="static"
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="StaticBackdropExampleLabel" >
                <CModalHeader onClose={() => closeModal()}>
                    <CModalTitle id="LiveDemoExampleLabel">Client Partner Mapping</CModalTitle>
                </CModalHeader>
                <CForm style={{ padding: '10px' }} className="row g-3 needs-validation update-main-form" validated={validated} onSubmit={handleFormSubmit} >
                    <CModalBody>
                        <CCol md={12}>
                            <CFormLabel htmlFor="validationClientName">
                                <p ref={messageRef} className={messageColor + " font-size-20px"} aria-live="assertive">&nbsp; {message} &nbsp;</p>
                            </CFormLabel>
                            <CInputGroup className="has-validation">
                                <CInputGroupText>Client</CInputGroupText>
                                <CFormInput type="text" aria-describedby="validationClientName" id="validationClientName" value={clientName} disabled feedbackInvalid="Required!" required></CFormInput>
                            </CInputGroup>
                        </CCol>


                        <CCol md={12}>
                            <CFormLabel htmlFor="validationAdvisor"></CFormLabel>
                            <CInputGroup className="has-validation">
                                <CInputGroupText>Partner</CInputGroupText>
                                <CFormSelect aria-describedby="validationAdvisor" id="validationAdvisor" value={agentId} onChange={(event) => { setAgentId(event.target.value); }} feedbackInvalid="Required!" required>
                                    <option value="">Select...</option>
                                    {
                                        advisors && advisors?.map((row) => <option key={row?.id} value={row?.id}  >{row?.fullName}</option>)

                                    }
                                </CFormSelect>
                            </CInputGroup>
                        </CCol>


                    </CModalBody>
                    <CModalFooter>
                        {/* <CButton color="danger" onClick={() => closeModal()}> Cancel </CButton> */}
                        {isSpenierOpen ?
                            <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
                            :
                            <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> Assign </CButton>
                        }
                    </CModalFooter>
                </CForm>
            </CModal>

            {/* Activities Modal */}
            <CModal
                size="xl"
                backdrop="static"
                visible={visibleA}
                onClose={() => closeModalA()}
                aria-labelledby="StaticBackdropExampleLabelActivities" >
                <CModalHeader onClose={() => closeModalA()}>
                    <CModalTitle id="LiveDemoExampleLabelActivities">Activities on {clientName} </CModalTitle>
                </CModalHeader>
                {/* <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleFormSubmit} ></CForm> */}
                <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form" validated={validatedA} onSubmit={handleFormSubmitA} >
                    <CModalBody>





                        {/* <ClientActivities id={1}></ClientActivities> */}

                        <CRow className="mb-12">
                            <div className="col-sm-9"> Note <span className='red'>*</span>
                                <CInputGroup className="has-validation">
                                    <CFormInput aria-describedby="validationCustomGoal" id="validationCustomGoal" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={activity} onChange={(event) => { setActivity(event.target.value); }} maxLength={255} required />
                                </CInputGroup>

                            </div>
                            {/* <div className="col-sm-3">Date <span className='red'>*</span>
                                <CInputGroup className="has-validation">
                                    <DatePicker
                                        //showMonthDropdown
                                        //showYearDropdown
                                        //yearDropdownItemNumber={100}
                                        //scrollableYearDropdown
                                        // id="validationlanguageTestReDate"
                                        aria-describedby="validationCustomActivityDate"
                                        // minDate={new Date()}
                                        className="form-control date-picker-custom"
                                        selected={Date.parse(activityDate)} onChange={(date) => setActivityDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                                        required
                                    />
                                </CInputGroup>
                            </div> */}
                            <div className="col-sm-3"> &nbsp;
                                <CInputGroup className="has-validation">
                                    {isSpenierOpen ?
                                        <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
                                        :
                                        <>
                                        <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> {activityActionText} </CButton> &nbsp;
                                        {isActivityEdited?<CButton className="btn btn-danger" onClick={() => { cleanActivity() }} style={{ borderRadius: "5px" }} color="danger" > Cancel </CButton>:''}
                                        </>
                                    }
                                </CInputGroup>

                            </div>
                        </CRow>


                    </CModalBody>



                    <CModalFooter style={{ display: "unset" }}>

                        <CTable align="middle" className="mb-0 border" hover responsive>
                            <CTableHead color="light">
                                <CTableRow>
                                    <CTableHeaderCell scope="col" width="5%"> SL</CTableHeaderCell>
                                    <CTableHeaderCell scope="col" width="55%">Note</CTableHeaderCell>
                                    <CTableHeaderCell scope="col" width="10%">Date</CTableHeaderCell>
                                    {/* <CTableHeaderCell scope="col" width="10%" className="text-center">Created Date</CTableHeaderCell> */}
                                    <CTableHeaderCell scope="col" width="15%" className="text-center">Created By</CTableHeaderCell>
                                    <CTableHeaderCell scope="col" width="15%" className="text-center" >Actions</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {userActivities?.length > 0 && userActivities?.filter((row) =>
                                    /* !searchedVal.length || row.fullName
                                    .toString()
                                    .toLowerCase()
                                    .includes(searchedVal.toString().toLowerCase())) */


                                    (row?.note)
                                        .toString()
                                        .includes(searchedVal.toString())

                                )?.map((row, i) => (
                                    <CTableRow v-for="item in tableItems" key={i}>
                                        <CTableDataCell>{i + 1} </CTableDataCell>
                                        <CTableDataCell>{row?.note}</CTableDataCell>
                                        {/* <CTableDataCell>{row?.date}</CTableDataCell> */}
                                        <CTableDataCell className="text-center">{row?.dateCreated}</CTableDataCell>
                                        <CTableDataCell className="text-center">{row?.userName}</CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            {row?.isOperational === 1 ?
                                                <>

                                                    <CButton color="success" onClick={() => { viewHandleEditActivities(row?.id, row?.note, row?.date) }} ><i className="fa fa-edit"></i></CButton> &nbsp;
                                                    <CButton color="danger" onClick={() => { deleteActivitiesConfirm(row?.id, row?.note, i) }} ><i className="fa fa-trash" aria-hidden="true"></i></CButton>
                                                </>
                                                :
                                                <>N/A</>
                                            }


                                        </CTableDataCell>

                                    </CTableRow>
                                ))}
                            </CTableBody>
                        </CTable>

                    </CModalFooter>
                </CForm>
            </CModal>



            <CRow >
                <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                    <h2>Approved Users List</h2>
                </CCol>
                <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                    {/* <div className="search">
                        <input type="text" id='searchItemId' placeholder="search" onKeyUp={(e) => { searchTable(e) }} />
                        <i className="fa fa-search"></i>


                    </div> */}

                    <CInputGroup className="has-validation">
                        <CInputGroupText> <i className="fa fa-search"></i></CInputGroupText>
                        <CFormInput id="searchItemId1" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={searchedVal} onChange={(e) => setSearchedVal(e.target.value)} />
                    </CInputGroup>
                </CCol>
            </CRow>



            {/* {console.log(users?.length)} */}
            {users?.length
                ? (
                    <div>

                        <CTable align="middle" className="mb-0 border" hover responsive>
                            <CTableHead color="light">
                                <CTableRow>
                                    <CTableHeaderCell className="text-center">
                                        <CIcon icon={cilPeople} />
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Country of Residance</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Mobile/Email</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Partner</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {users.filter((row) =>
                                    /* !searchedVal.length || row.fullName
                                    .toString()
                                    .toLowerCase()
                                    .includes(searchedVal.toString().toLowerCase())) */


                                    (row?.fullName + " " + row?.userId + " " + row?.userResidence[0]?.country?.name + " " + row?.mobile + " " + row?.officeEmail + " " + idToStatus(row?.applyStatus) + " " + idToAdvisor(row?.agentId) + " " + moment(row?.dateCreated).format("DD-MM-YYYY"))
                                        .toString()
                                        .toLowerCase()
                                        .includes(searchedVal.toString().toLowerCase())

                                )?.map((user, i) => (
                                    <CTableRow v-for="item in tableItems" key={i}>
                                        <CTableDataCell className="text-center">

                                            {(user?.pictureCode !== "" && user?.pictureCode !== null) ?
                                                <CAvatar size="md" className="profile-picture-css" src={user?.pictureCode} status={user?.applyStatus === '3' ? 'danger' : 'success'} />
                                                :
                                                <CAvatar size="md" className="profile-picture-css" src={require(`../assets/images/avatars/avater.png`)} status={user?.applyStatus === '3' ? 'danger' : 'success'} />
                                            }




                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>{user?.fullName}</div>
                                            <div className="small text-medium-emphasis">
                                                <span>{user?.applyStatus === 3 ? 'New Applied' : 'Approved'}</span> | Apply Date:{moment(user?.dateCreated).format("DD-MM-YYYY")}
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>{user?.userResidence?.map((row) => row?.country?.name)}</div>
                                            <div className="small text-medium-emphasis">
                                                ID:{user?.userId}
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div className="clearfix">
                                                {/* {user?.mobile} */}
                                                <div>{user?.mobile}</div>
                                                <div>{user?.officeEmail}</div>
                                                {/* <div className="small text-medium-emphasis">
                                                    ID:{user?.userId}
                                                </div> */}
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {user?.applyStatus === 4 ? "Assessment Ongoing" : user?.applyStatus === 5 ? "Info Submitted" : user?.applyStatus === 6 ? "Case Profile Reviewed" : user?.applyStatus === 7 ? "Discuss Later" : "More"}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {
                                                user?.agentId ?
                                                    //advisors?.map((row) => row.id === user.agentId?row.fullName?'');
                                                    <CFormSelect onChange={(e) => { openModal(user?.fullName, user?.id, e.target.value) }}>
                                                        <option value="" >Select</option>
                                                        {
                                                            advisors && advisors?.map((row) => <option key={row?.id} value={row?.id} selected={row?.id === user.agentId ? 'selected' : ''} >{row?.fullName}</option>)

                                                        }
                                                    </CFormSelect>


                                                    // <CButton  type="submit" color="success"   onClick={() => { openModal(user?.fullName,user?.id) }} > {advisors?.map} </CButton>
                                                    :
                                                    // <CButton  type="submit" color="success"   onClick={() => { openModal(user?.fullName,user?.id,user?.agentId) }} > Map </CButton>

                                                    <CFormSelect onChange={(e) => { openModal(user?.fullName, user?.id, e.target.value) }}>
                                                        <option value="" selected>Select</option>
                                                        {
                                                            advisors && advisors?.map((row) => <option key={row?.id} value={row?.id} selected={row?.id === user.agentId ? 'selected' : ''} >{row?.fullName}</option>)

                                                        }
                                                    </CFormSelect>
                                            }

                                        </CTableDataCell>
                                        <CTableDataCell width={220}>
                                            <CButton type="submit" color="info" onClick={() => { viewHandleClick(user) }} ><i className="fa fa-comment"></i></CButton> &nbsp;
                                            <CButton type="submit" color="info"   > <CLink target="_blank" href={'/#/home/user-details/' + user?.id} style={{ color: "black" }} > <i className="fa fa-eye"></i> </CLink> </CButton> &nbsp;
                                            <CButton type="submit" color="success" onClick={() => { viewHandleEditClick(user?.id) }} ><i className="fa fa-edit"></i></CButton> &nbsp;
                                            <CButton type="submit" color="danger" onClick={() => { deleteUserInfo(user?.id, user?.userName, user?.fullName, i) }} ><i className="fa fa-trash" aria-hidden="true"></i></CButton>
                                        </CTableDataCell>
                                    </CTableRow>
                                ))}
                            </CTableBody>
                        </CTable>


                    </div>
                ) : <p>No users to display</p>
            }
        </article>
    )
}
export default ApprovedUsers;
