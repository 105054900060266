import React from 'react'
import { Helmet } from 'react-helmet'
import './../assets/css/custom.css'
import logo from './../assets/images/success-1.png';
// import Meta from './Meta';


const SuccessStories = () => {
    const url = "https://mygrationhub.com";
    const title = "MyGrationHub | A reliable website for every student, traveler, and immigrant. ";
    const keywords = "Migration hub, migrationhub, MygrationHub, MyGration Hub, My Gration Hub";
    const description = "I had a strong desire to make Canada my permanent home, but the more I delved into the process, the more challenging it appeared.";
    const image = url + logo;

    return (
        <div>
            {/* <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta> */}
            <Helmet>

                <title>{title}</title>
                <meta name="title" content={title}></meta>
                <meta name="keywords" content={keywords}></meta>
                <meta name="description" content={description}></meta>

                <meta property="og:title" content={title}></meta>
                <meta property="og:description" content={description}></meta>
                <meta property="og:image" content={image}></meta>

                <meta name="twitter:title" content={title}></meta>
                <meta name="twitter:description" content={description}></meta>
                <meta name="twitter:image" content={image}></meta>

            </Helmet>
            
            <section className="padding-top-adjuster common-segment success-stories" id="success-stories">

                <div className="container">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="section-heading padding-top-40px">
                                <h2>Success Stories </h2>
                            </div>
                        </div>

                        <div className="col-lg-12">


                            <div className="item">

                                <div className="row">

                                    <div className="col-lg-6 align-self-center">
                                        <div className="row" >
                                            <div className="col-lg-12">
                                                <div className="accordions-rigth">
                                                    <div className="inner-accordions-rigth">
                                                        <p>
                                                            I had a strong desire to make Canada my permanent home, but the more I delved into the process, the more challenging it appeared. At one point, I considered giving up and staying put, thinking the journey was too difficult. The key lies in the advice you receive – not everyone is reliable. Simply wishing to live in Canada permanently isn&apos;t enough; you need a trustworthy guide to help you navigate the right path and provide constant support and information to help you achieve your goals. If this path isn&apos;t right for you, it&apos;s okay to say no from the beginning.
                                                        </p>
                                                        <p>

                                                            My dream of a better life and a beautiful future for my children has been a long-held aspiration. I&apos;ve always believed that if you truly want something, you can achieve it. That&apos;s exactly what happened for my family – we are now proud permanent residents of Canada. My journey with Migration Hub mirrors this sentiment. They became a friend during my preparation period, supporting me without making the impossible seem possible or trying to gain any undue advantage. Thanks, Migration Hub! Keep empowering deserving individuals from our country to showcase their talents globally. Let&apos;s prove ourselves just like the remarkable countries around the world, hand in hand with Migration Hub.
                                                        </p>
                                                        <p>

                                                        </p>
                                                    </div>

                                                    <div className="main-button-red">
                                                        <div className="scroll-to-section">
                                                            <p className='familyNameHead'>
                                                                Mohammad Jaynul Abedin & Family
                                                            </p>
                                                            <p className='familyNameDown'>
                                                                Permanent Resident – Canada<br />
                                                                Ex Director (Standered Bank, Bangladesh)
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="accordions is-first-expanded accordions1">
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="item">

                                <div className="row">

                                    <div className="col-lg-6">
                                        <div className="accordions is-first-expanded accordions2">

                                        </div>
                                    </div>

                                    <div className="col-lg-6 align-self-center">
                                        <div className="row" >
                                            <div className="col-lg-12">
                                                <div className="accordions-rigth">
                                                    <div className="inner-accordions-rigth overflow-overflow">
                                                        <p>
                                                            In 2010, my family and I embarked on our Canada immigration journey, a dream shared by many in Bangladesh. After completing my IELTS and credential assessment, we enlisted the services of an immigration consultancy firm in Bangladesh. Unfortunately, our initial optimism was met with disappointment, as it became evident that their encouraging words were primarily a tactic to secure our registration and initial payments.
                                                        </p>
                                                        <p>
                                                            Two years into this discouraging experience, on the brink of giving up, we were fortunate to be introduced to Mygration Hub through a colleague. What immediately set Mygration Hub apart was their unwavering honesty, professionalism, and commitment to crafting personalized plans for each individual. From the outset, they provided genuine guidance, ensuring that every step of our journey – from form submissions to our eventual landing in Canada – was meticulously navigated.
                                                        </p>
                                                        <p>
                                                            Unlike our previous encounter, Mygration Hub&apos;s support extended beyond the immediate requirements. Even after securing Permanent Residency in Canada in June 2022, our connection with Mygration Hub persists, with their team readily available for any assistance we may require.
                                                            Their dedication and transparency transformed our immigration experience into a success story, making our dream a reality.
                                                        </p>
                                                        <p>
                                                            I wholeheartedly endorse Mygration Hub for their exceptional services and express my sincere gratitude for guiding us to achieve our Canadian dreams. Their integrity, professionalism, and ongoing support make them a standout immigration consultancy firm. I wish Mygration Hub continued success in their future endeavors, confident that they will continue to help others realize their aspirations of settling in Canada.
                                                        </p>
                                                    </div>

                                                    <div className="main-button-red">
                                                        <div className="scroll-to-section familyNameHeadrRight">

                                                            <p className='familyNameHead'>
                                                                Enayetur Rahim & Family
                                                            </p>
                                                            <p className='familyNameDown'>
                                                                Permanent Resident – Canada<br />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="item">

                                <div className="row">

                                    <div className="col-lg-6 align-self-center">
                                        <div className="row" >
                                            <div className="col-lg-12">
                                                <div className="accordions-rigth">
                                                    <div className="inner-accordions-rigth">
                                                        <p>
                                                            Anyway, Canada didn&apos;t seem like the perfect fit for someone as disorganized as me, caught up in the hustle and bustle of the country. Moving from agency to agency, navigating through university requirements, dealing with IELTS—how would it all work out? But they say where there&apos;s a will, there&apos;s a way. It started by sharing my aspirations with the Migration Hub. Suddenly, the seemingly impossible tasks became surprisingly easy.
                                                        </p>
                                                        <p>
                                                            Collecting all the necessary documents, I sat down to take the IELTS one early morning. Just when everything was set, my son was born. I realized I might have to start from scratch again, but the Migration Hub guided me through that too. One afternoon, I bought a ticket and landed at Pearson Airport. Despite the challenge of deciphering my own handwriting, my journey turned out to be easier than expected!
                                                        </p>
                                                        <p>
                                                            The key was communicating with the right people at the right time. The Migration Hub made all the difference by providing the necessary guidance. Looking back, I can confidently say that every step, every challenge, and even the unexpected joys have shaped my Canadian journey into something uniquely mine.
                                                        </p>
                                                    </div>

                                                    <div className="main-button-red">
                                                        {/* <div className="scroll-to-section">

                                                            <p className='familyNameHead'>
                                                                Shegufta Farhat & Family
                                                            </p>
                                                            <p className='familyNameDown'>
                                                                Permanent Resident – Canada<br />

                                                            </p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="accordions is-first-expanded accordions3">


                                        </div>
                                    </div>

                                    <div className='col-lg-12'>
                                        <p>In 2010, my family and I embarked on our Canada immigration journey, a dream shared by many in Bangladesh. After completing my IELTS and credential assessment, we enlisted the services of an immigration consultancy firm in Bangladesh. Unfortunately, our initial optimism was met with disappointment, as it became evident that their encouraging words were primarily a tactic to secure our registration and initial payments.</p>
                                        <p>Two years into this discouraging experience, on the brink of giving up, we were fortunate to be introduced to Mygration Hub through a colleague. What immediately set Mygration Hub apart was their unwavering honesty, professionalism, and commitment to crafting personalized plans for each individual. From the outset, they provided genuine guidance, ensuring that every step of our journey – from form submissions to our eventual landing in Canada – was meticulously navigated.</p>
                                        <p>Unlike our previous encounter, Mygration Hub&apos;s support extended beyond the immediate requirements. Even after securing Permanent Residency in Canada in June 2022, our connection with Mygration Hub persists, with their team readily available for any assistance we may require. Their dedication and transparency transformed our immigration experience into a success story, making our dream a reality.</p>
                                        <p>I wholeheartedly endorse Mygration Hub for their exceptional services and express my sincere gratitude for guiding us to achieve our Canadian dreams. Their integrity, professionalism, and ongoing support make them a standout immigration consultancy firm. I wish Mygration Hub continued success in their future endeavors, confident that they will continue to help others realize their aspirations of settling in Canada.</p>

                                        <div className="scroll-to-section">

                                            <p className='familyNameHead'>
                                                Shegufta Farhat & Family
                                            </p>
                                            <p className='familyNameDown'>
                                                Permanent Resident – Canada<br />

                                            </p>
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </section>

        </div>

    )
}

export default SuccessStories